import React, { useRef } from 'react'

import Slider from "react-slick"


const Popular = ({ populars }) => {
  const slider = useRef()

  const sliderSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      }
    ]
  }

  return (
    <div class="most_popular__section mainpage-slider">
      <div class="wrap">
        <div class="wrap_float">
          <div class="top_part">
            <div class="top_part_left">
              <p class="section_subtitle">Les populaires</p>
              <h2 class="section_title">
                Nos séjours<br/>les plus populaires
              </h2>
            </div>
            <div class="top_part_right">
              <a href="/stays" class="btn">
                  <span>Voir tous les séjours</span>
                </a>
              <div class="controls" id="most_popular__arrows">
                <div class="arrow prev" onClick={() => slider.current.slickPrev()} />
                <div class="arrow next" onClick={() => slider.current.slickNext()} />
              </div>
            </div>
          </div>
          <div class="most_popular__section__slider" id="most_popular__slider">
            <Slider {...sliderSettings} ref={slider} style={{heigh: 510}}>
              {populars.map(popular => (
                <a href={popular.url} class="slider_item">
                  {popular.datas.recommand ? <div class="recommand-offer">Nous recommandons</div> : ''}
                  <img src={popular.images.background} alt={popular.name} />
                  <div class="slider_item__content">
                    {popular.datas.stars ? <div class="rating">
                      <div class="name">{popular.datas.starsName}</div>
                      <div class="stars">
                        {popular.datas.stars > 0 ? <div class="star active" /> : ''}
                        {popular.datas.stars > 1 ? <div class="star active" /> : ''}
                        {popular.datas.stars > 2 ? <div class="star active" /> : ''}
                        {popular.datas.stars > 3 ? <div class="star active" /> : ''}
                        {popular.datas.stars > 4 ? <div class="star active" /> : ''}
                      </div>
                    </div> : ''}  
                    <h3 class="title">
                      {`${popular.name} | ${popular.datas.price} €`}
                    </h3>
                    <p class="description">
                      {popular.description}
                    </p>
                    <div class="days">
                      <span>{`${popular.datas.days} jours | ${popular.datas.nights} nuits`}</span>
                    </div>
                  </div>
                </a>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>  
  )
}

export default Popular