import covidVrTravel from './covid-vr-travel'
import covidFranceAssouplissement from './covid-france-assouplissement'
import earthHour from './earth-hour'

const all = [
  earthHour,
  covidVrTravel,
  covidFranceAssouplissement
]

const categories = [
  {
    name: 'Innovation',
    articles: [
      covidVrTravel,
    ]
  },
  {
    name: 'Voyage',
    articles: [
      covidFranceAssouplissement
    ]
  },
  {
    name: 'Autres',
    articles: [
      earthHour
    ]
  }
]

export { all, categories }