import React from 'react'

import articlePicture from '../../assets/img/articles/covid-france-assouplissement.jpg'

const article = {
  slug: 'covid-france-assouplissement',
  name: 'La France assouplit les conditions de voyage dès vendredi pour sept pays',
  description: 'La France annonce un allègement des restrictions d\'entrée sur le territoire pour certains pays dès vendredi 12 mars.',
  content: <>
    <h1>
    La France a annoncé, jeudi, que les mesures d’entrée sur le territoire, imposées pour lutter contre la pandémie de Covid-19, allaient être assouplies pour un certain nombre de pays hors Union européenne dont le Royaume-Uni, Israël et le Japon et ce dès le 12 mars. 
    </h1>
    <p>
    Un allègement des restrictions sanitaires pour les voyageurs souhaitant entrer dans l’Hexagone. À partir de vendredi, la France va assouplir les conditions d'entrée et de sortie de son territoire pour certains pays situés hors de l'espace européen, annonce jeudi 11 mars un communiqué du Quai d'Orsay.   
    </p>
    <p>
    "Il ne sera plus nécessaire de justifier d’un motif impérieux pour les déplacements en provenance ou vers l’Australie, la Corée du Sud, Israël, le Japon, la Nouvelle Zélande, le Royaume-Uni et Singapour, du fait de la diffusion très large du variant britannique en France et de la situation sanitaire spécifique de ces pays", indique ce communiqué.   
    </p>
    <p>
    Depuis le 1er février, toute entrée en France et toute sortie du territoire à destination ou en provenance d'un pays extérieur à l'Union européenne est interdite sauf motif impérieux, et toute entrée à partir d'un pays de l'Union européenne est conditionnée à la réalisation d'un test PCR, à l'exception des travailleurs transfrontaliers. 
    </p>
    <p>
    © 2021 Reuters
    </p>
  </>,
  category: 'Innovation',
  date: 'Mars 2021',
  image: articlePicture
}

export default article