import exchange from "../assets/img/benefits/home/exchanging.svg"
import traveling from "../assets/img/benefits/home/relaxing.svg"
import working from "../assets/img/benefits/home/working.svg"

const benefitsDatas = [
  {
    name: 'Travailler',
    color: 'rgb(206, 76, 137)',
    description: 'Un séjour qui te permet de pouvoir travailler dans une cadre idyllique',
    image: working
  },
  {
    name: 'Se détendre',
    color: 'rgb(254, 184, 19)',
    description: 'Une destination de rêve afin de pouvoir te détendre après une journée productive',
    image: traveling
  },
  {
    name: 'Echanger',
    color: 'rgb(4, 183, 206)',
    description: 'Un séjour avec d\'autres indépendants ayant le même état d\'esprit que toi',
    image: exchange
  }
]

export default benefitsDatas
