import React from "react"
import { Helmet } from 'react-helmet'
import { withPrefix } from "gatsby"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"

import Header from '../components/header'
import Head from '../components/home/head'
import Popular from '../components/home/popular'
import Benefits from '../components/home/benefits'
//import SearchTour from '../components/home/searchTour'
//import Cities from '../components/home/cities'
//import Countries from '../components/home/countries'
//import Discount from '../components/home/discount'
import Articles from '../components/home/articles'
import Footer from '../components/footer'

import ads from '../data/staysFull/ads'
//import categories from "../data/categories"
import populars from '../data/staysFull/populars'
import benefits from '../data/benefits'

import company from '../data/company'

const IndexPage = ({ location }) => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoTraveling - Séjours entre indépendants</title>
        <script src={withPrefix('axeptio.js')} type="text/javascript" />
        <link rel="shortcut icon" type="image/png" href={company.icon} />
        <meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
        <meta name="image" content={company.icon} />
        <meta name="author" content={company.name} />
        <meta property="og:title" content="CoTraveling - Séjours entre indépendants" />
        <meta property="og:image" content={company.linkedinMetaImage} />
        <meta property="og:description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
        <meta property="og:url" content="https://cotraveling.io" />
        <meta property="og:type" content="product" />
        <meta property="og:site_name" content="CoTraveling" />
      </Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <Head ads={ads} /*categories={categories}*/ />
        <Popular populars={populars} />
        <Benefits benefits={benefits} />
        {/*<SearchTour />*/}
        {/*<Cities />*/}
        {/*<Countries />*/}
        {/*<Discount />*/}
        <Articles />
        <Footer pathname={location.pathname} />
      </div>
    </main>
  )
}

export default IndexPage
