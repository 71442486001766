import React from 'react'

import articlesHeader from '../../assets/img/articles.webp'

import { all as articlesDatas } from '../../data/articles'

const Articles = () => {
  return (
    <div class="blog blog-section last-section" style={{backgroundImage: `url(${articlesHeader})`}}>
      <div class="wrap">
        <div class="wrap_float">
          <div class="top_part">
            <div class="section_subtitle">
              BLOG
            </div>
            <h2 class="section_title">
              CoTraveling Articles
            </h2>
            <div class="section_description">
              Une petite zone pour vous tenir informer de nos derniers articles !<br/>(On avoue, c'est pour le référencement)
            </div>
          </div>
          <div class="section_content">
            {articlesDatas.slice(0, 2).map(article => (
              <a key={article.name} href={`/article/${article.slug}`} class="blog_item">
                <div class="blog_item_img">
                  <img src={article.image} alt={article.name} />
                </div>
                <div class="blog_item_date">
                  {`${article.date} | ${article.category}`}
                </div>
                <h3 class="blog_item_title">
                  {article.name}
                </h3>
                <p class="blog_item_text">
                  {article.description}
                </p>
              </a>
            ))}
          </div>
          <div class="show-more">
            <a href="/articles" class="btn">
              <span>Voir tous les articles</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Articles