import React from 'react'

import articlePicture from '../../assets/img/articles/covid-vr-travel.jpg'

const article = {
  slug: 'covid-vr-travel',
  name: 'La pandémie dope les voyages en réalité virtuelle',
  description: 'Les restrictions imposées par le Covid-19 suscitent un intérêt croissant pour ces plongeons en réalité virtuelle grâce à de nouvelles applications.',
  content: <>
    <p>
    Confinée chez elle au Pays de Galles depuis un an en raison de la pandémie, Jem Jenkins Jones a réussi à tenir la promesse faite à sa fille de 10 ans de voir les aurores boréales d'Islande ou les réserves d'Afrique du Sud... en réalité virtuelle.
    </p>
    <p>
    "Elle a été épatée!", explique-t-elle, qualifiant ces expériences de "bouées de sauvetage".
    </p>
    <p>
    Les restrictions imposées par le Covid-19 suscitent un intérêt croissant pour ces plongeons en réalité virtuelle grâce à de nouvelles applications, et moyennant quelques centaines de dollars d'investissement pour le casque et/ou la console nécessaires à leur fonctionnement.
    </p>
    <p>
    Tout est possible: une virée au Machu Picchu (Pérou), dans les forêts tropicales de Bornéo, ou un "road trip" à travers les Etats-Unis au volant d'un cabriolet.
    </p>
    <p>
    Les données sur l'utilisation des voyages en réalité virtuelle sont encore limitées, mais leurs développeurs assurent que la demande explose, comme Cezara Windrem, créateur de la plate-forme Alcove VR chez AARP Innovation Labs.
    </p>
    <p>
    "Nous avons toujours plus d'adhésions chaque mois", dit-il.
    </p>
    <p>
    Grâce à Alcove, les utilisateurs peuvent visiter des lieux exotiques tels que les récifs coralliens d'Australie et l'île de Malte, ou partager une expérience virtuelle avec un membre de la famille qui n'a pas les compétences techniques nécessaires.
    </p>
    <p>
    "De nombreuses personnes ont décidé d'acheter un casque pour les membres âgés de leur famille", explique M. Windrem. "Cela permet des voyages ensemble pendant le confinement."
    </p>
    <p>
    D'autres jouent aux échecs avec une personne habitant de l'autre côté de la planète.
    </p>
    <p>
    - Substitut complémentaire -
    </p>
    <p>
    Avec une industrie du tourisme terrassée par la pandémie, la réalité virtuelle est devenue à la fois un substitut aux voyages dans le monde réel et un complément permettant de planifier ses prochains voyages.
    </p>
    <p>
    Les développeurs d'applications ont donc créé une gamme d'expériences spécifiques: visiter les pyramides d'Egypte, le Taj Mahal, les savanes du Kenya ou l'Antarctique à partir d'un kayak, avec l'aide d'opérateurs commerciaux ou d'organisations telles que le National Geographic ou le World Wildlife Fund.
    </p>
    <p>
    Les utilisateurs peuvent opter pour du matériel Oculus de Facebook, pour la PlayStation de Sony ou le Google Cardboard bon marché.
    </p>
    <p>
    "Chaque semaine depuis le début de la pandémie, j'ai voyagé dans le confort de ma maison", se réjouit ainsi Rafael Cortes, un informaticien de San Antonio qui utilise Alcove et YouTube VR.
    </p>
    <p>
    "Je suis allé à Londres, sur le pont de verre en Chine, aux chutes de l'Ange au Venezuela, dans l'ancienne ville de Petra en Jordanie et j'ai fait un tour de New York en hélicoptère", détaille-t-il.
    </p>
    <p>
    Amy Erdt vit, elle, à Portland, dans l'Oregon, mais elle "se promène" aussi dans la ville de sa sœur à Wallingford, en Angleterre.
    </p>
    <p>
    Elle administre un groupe Facebook d'utilisateurs de réalité virtuelle et assure avoir des "expériences de voyage" virtuelles remarquablement réalistes.
    </p>
    <p>
    "Une fois, je me suis assise à une table de poker en réalité virtuelle à 1 heure du matin avec un gars en Australie qui mangeait du KFC", raconte-t-elle. "Je pouvais même entendre le craquement" du poulet.
    </p>
    <p>
    - Un engouement durable? -
    </p>
    <p>
    Pour l'heure, les applications les plus populaires restent celles du monde des jeux et de la fantaisie.
    </p>
    <p>
    Mais les voyages ont le vent en poupe.
    </p>
    <p>
    "Alors qu'on est isolé socialement, cela peut sembler bizarre de s'isoler davantage pour se transporter ailleurs, mais cela nous permet de vivre des choses que nous ne pouvons pas vivre aujourd'hui", observe Avi Greengart, analyste au cabinet de conseil Techsponential.
    </p>
    <p>
    Bien sûr, dit-il, il manque aux voyages en réalité virtuelle les expériences culinaires ou sensorielles. "On ne fait pas non plus de rencontres fortuites avec les habitants", reconnaît-il.
    </p>
    <p>
    Mais on peut au contraire avoir un musée pour nous tout seul, "impossible dans le monde physique", ajoute-t-il.
    </p>
    <p>
    Avant la pandémie, la réalité virtuelle et augmentée avait commencé à séduire les voyagistes et les offices de tourisme qui y ont vu le moyen de donner un avant-goût des séjours proposés. Pour les clients, c'est un moyen de peaufiner leur voyage, selon un rapport du cabinet d'études GlobalData.
    </p>
    <p>
    Finalement, la crise sanitaire aura permis de montrer que ce ne sont pas de simples gadgets, conclut Ralph Hollister, de GlobalData.
    </p>
    <p>
    © 2021 AFP
    </p>
  </>,
  category: 'Voyage',
  date: 'Mars 2021',
  image: articlePicture
}

export default article