import React, { useState, useRef } from 'react'

import companyDatas from '../../data/company'
import Slider from "react-slick"

const Head = ({ ads/*, categories*/ }) => {
  const [scrolled, setScrolled] = useState(false)

  const slider = useRef()

  const toggleScrolled = () => {setScrolled(prevScrolled => !prevScrolled)}

  const sliderSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div class="homepage_slider">
      <div class="slider-container">
        <Slider {...sliderSettings} ref={slider}>
          {ads.map(ad => (
            <div>
              {/*<img src={ad.img} alt={ad.name} />*/}
              <div class="slide__bg" style={{backgroundImage: `url(${ad.images.background})`}}></div>
              <div class="slide__content">
                <div class="slide__text">
                  <h2 class="slide__text-heading">{ad.name}</h2>
                  <h2 class="slide__text-desc">{ad.descriptionHead}</h2>
                  <div class="slide__controls">
                    <a href={ad.url} class="btn">Voir plus d'informations</a>
                    {false ? <a href="tour-list.html" class="btn btn__choose_tour">Choose tour</a> : ''}
                    <a class="arrow next" onClick={() => slider.current.slickNext()} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <h1 class="headTagline">{companyDatas.description}</h1>
      {/*<div class={`categories ${scrolled ? 'scrolled' : ''}`} id="header_categories">
        <div class="wrap">
          <div class="wrap_float">
            <div class="items">
              <div class="scroll">
                {categories.map(category => (
                  <a key={category.name} href={category.url} class="categories_item">
                    <div class="icon">
                      <div class="icon-wrap">
                        <img src={category.img} alt="" class="image-cover" />
                      </div>
                      </div>
                    <div class="_title"><span>{category.name}</span></div>
                  </a>
                ))}
              </div>
            </div>
            <div class="other_items" id="header_other_items" onClick={toggleScrolled}>
              <div class="categories_item item">
                <div class="icon">
                  <div class="arrow" />
                </div>
                <div class="_title">Voir 4 autres catégories</div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  )
}

export default Head