import React from 'react'


const Benefits = ({ benefits }) => {
  return (
    <div class="benefits">
      <div class="wrap">
        <div class="wrap_float">
          {benefits.map(benefit => (
            <div key={benefit.name} class="item">
              <div class="item_img">
                <img src={benefit.image} alt={benefit.name} />
              </div>
              <div class="tc">
                <h3 class="item_title" style={{color: benefit.color}}>{benefit.name}</h3>
                <p class="item_text">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Benefits